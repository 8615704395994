@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


/* ----------custom css--------- */

:root{
  --accent-orange : #f08b38;
  --accent-gray : #878a8d;
  
}

/* flex gap-2 items-center curosr-pointer text-white bg-[var(--accent-orange)] border border-[#f99004]  text-center mb-[1rem] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] font-[500]  */

.accent-btn{
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  color: white;
  background-color: var(--accent-orange);
  border: 1px solid #f99004 ;
  text-align: center;
  margin-bottom: 1rem;
  padding   : 0.7rem 1.5rem ;
  border-radius: 0.358rem;
  /* font-weight: 500; */
}

.form-label-size{
  /* font-size: 0.857rem; */
  font-size: 12px;
}

.form-title-size{
  font-size: 13px;
  font-weight: 450;
  
}

.checkbox-white {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid var(--accent-orange);
  background-color: transparent;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.checkbox-white:checked {
  background-color: var(--accent-orange);
}

.checkbox-white:checked::before {
  content: "";
  display: block;
  width: 10px;
  height: 5px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform:  rotate(-45deg) scaleX(-1);
  margin-left: 2px;
  margin-top: 2px;
}

/* ------------------------------------- */




body {
  font-family: "Roboto", sans-serif;

  background: #f9f9f9;
}

button,
[type="button"] {
  background-color: #07499a;
}

.ant-btn-default {
  display: none;
}

.btn {
  background: linear-gradient(180deg, #0092d7 0%, #003d7e 100%), #004d8e;
}

.nav_item {
  background: linear-gradient(180deg, #0092d7 0%, #003d7e 100%);
}

.nav_link:hover {
  background: linear-gradient(180deg, #45b4e8 0%, #3e92eb 100%);
}



.inactive {
  background: #000;
}

/* .ant-tabs-ink-bar {
  background-color: transparent;
}   */

.sc-eDWCr {
  font-size: 16px;
  color: #5f6368;
  line-height: 18.75px;
  font-weight: 400;
}

/* sc-dkrFOg iSAVrt rdt_TableHeadRow */
/* hbotQl */
sc-gswNZR beUiVL rdt_TableHead .iSAVrt {
  background-color: red !important;
}

/* iSAVrt */

sc-lllmON gMliOo .jaZsat {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  background: red !important;
}

/* .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #BF2D30 !important;
  z-index: 2;
} */
/* 
.ant-tabs-tab{
  border: none!important;
} */

.rr {
  transform: rotate(90deg);
}

.sc-dkrFOg {
  background: #f9f9f9 !important;
}

.hh {
  fill: white;
}

.nav_link:hover svg path {
  fill: white;
}

.cc:hover svg path {
  stroke: white;
}

.cc:hover svg path {
  fill: white;
}

/* svg:hover path {
  fill: red;
} */

/* :where(.css-dev-only-do-not-override-sk7ap8) */
/* for selector button */
.ant-select-selector {
  /* border: none !important;
  border: 1px solid #C9C9C9 !important; */
  border-radius: 0 !important;
  height: 38px !important;
  /* height: 40px !important; 
  border-right: 0 !important;
  color: #505056 !important; */
}

.ant-select-selection-item {
  margin-top: 2px !important;
}

@media (min-width: 1366px) {
  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 19px;
  }
}

.ant-input-password {
  border: 0px !important;
  background-color: #eeeeee !important;
  border-radius: 0px !important;
}

.ant-input {
  background-color: transparent !important;
}


/* .ant-drawer-right>.ant-drawer-content-wrapper {
  top: 0;
  right: -121px !important;
} */


.ant-drawer-left>.ant-drawer-content-wrapper {

  left: -114px !important;

}

.ant-drawer .ant-drawer-header-title {

  justify-content: end !important;

}

.ant-drawer .ant-drawer-header {

  border-bottom: 0px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-drawer .ant-drawer-header {

  padding: 25px 5px !important;
  /* display: none; */

}


.recharts-wrapper{
  width:  100%;
}

/* .ant-drawer {
  overflow-y: hidden !important;
} */



@keyframes myvariable {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(250px);
  }
}

.formskeleton{
  animation: myvariable 2s linear infinite;
}


@keyframes myvariable1 {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.formrowskeleton{
  animation: myvariable1 0.1s linear infinite;
}


.pagination {
  display: flex;
  justify-content: center;
  gap: 30px ;
  align-items: center;
  background-color: #F3F2F7;
  border-radius: 50px;
  /* padding :  5px; */
}

.pagination__button {
  background-color: #f3f2f7;
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  /* overflow: hidden; */
  color: #333;
  cursor: pointer;
  /* padding: 5px;
  padding-left: 20px;
  padding-right: 20px; */
}

.pagination__button:hover {
  /* background-color: #F08B38; */
  color: #F08B38;
}

.activepagination{
  color: white;
  background-color: #F08B38;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50%;
}



 /* for table heading color change */
.rdt_TableHeadRow{
  background-color: #E5F0F7!important;
}




input[type="file"]::-webkit-file-upload-button{
  border:none;
  background-color: transparent;
  border-right: 2px solid gray;
  color:gray;  

}

input[type="file"]::-webkit-file-upload-button::after{
  content: "select file lll";
  color: red;
} 


